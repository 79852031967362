import Choices from 'choices.js';
import { manageActionsFromArray } from './custom_events';

export let choicesList = {};

// Load ChoicesJS only for elements that are not in the list yet
export function loadChoicesJS() {
  // console.log(document.getElementsByClassName('choices-js'));
  Array.from(document.getElementsByClassName('choices-js')).forEach(element => {
    if(!element.disabled && !choicesList.hasOwnProperty(element.name)) {
      loadOneChoicesJS(element);
    }
  });
}

export function reloadChoicesJS() {
  // console.log(document.getElementsByClassName('choices-js'));
  Array.from(document.getElementsByClassName('choices-js')).forEach(element => {
    loadOneChoicesJS(element);
  });
}

function loadOneChoicesJS(element) {
  choicesList[element.name] = new Choices(element, {
    searchResultLimit: 5,
    loadingText: "Chargement...",
    noResultsText: "Pas de résultats",
    itemSelectText: "",
    fuseOptions: {
      includeScore: true,
      thresold: 0.0,
      minMatchCharLength: 3
    },
  });
}

export function goToPage(url) {
  window.location = url;
}

export function replacePage(url) {
  window.location.replace(url);
}

export function confirmOK(text) {
  const ask = confirm(text);

  return ask === true;
}

// Method to replicate jquery "on" method
export const on = (selector, eventType, childSelector, eventHandler) => {
  const elements = document.querySelectorAll(selector)
  for (const element of elements) {
    element.addEventListener(eventType, eventOnElement => {
      if (eventOnElement.target.matches(childSelector)) {
        eventHandler(eventOnElement)
      }
    })
  }
}

// HTML manipulation
export function replaceElement(element, html) {
  element.insertAdjacentHTML('beforebegin', html);
  element.remove();

  // This does not work properly with tables
  // console.log(document.createRange().createContextualFragment(html))
  // element.parentNode.replaceChild(document.createRange().createContextualFragment(html), element);
}

// Remplit les champs (s'ils ne sont pas remplis) Greffe et SIREN automatiquement
// si la société est une "Société étrangère non inscrite au RCS"
export function fillForeignLegalForm(element) {
  if(element.value != '3220') return;

  let actions = [
    { action: 'set_value_if_empty', target: '.search-siren-field', target_type: 'query_selector', value: '.' },
    { action: 'set_value_if_empty', target: '.court-select', target_type: 'query_selector', value: '.' }
  ];

  manageActionsFromArray(actions);
}

export function showLoader(text) {
  document.getElementById('hover-body').classList.remove('d-none');
  // Si l'élément contient un texte particulier, remplacer le texte par défaut.
  let textElement = document.getElementById('hover-body-text');
  if(text) textElement.innerHTML = text;

  textElement.classList.remove('d-none');
}

export function hideLoader() {
  document.getElementById('hover-body').classList.add('d-none');
  document.getElementById('hover-body-text').classList.add('d-none');
}