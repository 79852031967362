const axios = require('axios');
import { replaceElement, loadChoicesJS, reloadChoicesJS } from '../javascripts/utils';

export function manageFormErrors(errors) {
  // console.log('errors');
  document.querySelectorAll('.invalid-feedback').forEach(element => {
    element.innerHTML = '';
    // element.classList.add('d-none');
  });
  document.querySelectorAll('.form-control').forEach(element => element.classList.remove('is-invalid'));

  for (const key in errors) {
    // console.log(key);
    let element = document.getElementById(`error-${key}`);
    if(errors[key].constructor.name === "Array") {
      element.innerHTML = errors[key][0];
    } else {
      element.innerHTML = errors[key];
    }
    // This behaviour is mandatory for checkboxes errors
    // In checkbox case, the input is not the previousElementSibling
    element.parentNode.getElementsByTagName('input').forEach(element => element.classList.add('is-invalid'));
    // Also do it for select
    // https://demat-facile.monday.com/boards/327646012/pulses/1940940037
    element.parentNode.getElementsByTagName('select').forEach(element => element.classList.add('is-invalid'));
    // element.previousElementSibling.classList.add('is-invalid');
    // Also do it for textareas
    element.parentNode.getElementsByTagName('textarea').forEach(element => element.classList.add('is-invalid'));
  }

  // Focus on first form field with error
  document.querySelectorAll('.is-invalid')[0].focus();
}

export function needRecording() {
  return document.body.hasAttribute('data-record-actions');
}

export function recordAction(event, field, value, id) {
  console.log(event);
  console.log(field);
  console.log(value);
  console.log(id);

  axios({
    url: document.getElementById('form_content_actions_url').value,
    method: 'post',
    headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content },
    data: {
      event_name: event,
      field_name: field,
      value: value,
      field_id: id
    } });
}

export function updateFormRubric(newValue, name, updateType) {
  axios({
    url: `${document.getElementById('update_form_rubric_url').value}.json`,
    method: 'patch',
    headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content },
    data: {
      field_ids: [...document.querySelectorAll('.form-field')].map(item => item.id),
      new_value: newValue, rubric_path: name,
      element_ids: [...document.querySelectorAll('.form-element')].map(item => item.id),
      multiple_ids: [...document.querySelectorAll('.form-multiple')].map(item => item.id),
      update_type: updateType
    } })
    .then(function (response) {
      const data = response.data;
      // console.log(response.data);
      // Put reloaded delements first to refresh as fast as possible
      parseRubricsDisplay(data);
      parseElementsDisplay(data);
      parseMultiplesDisplay(data);
      rebuildSidebar(data);
      rebuildLegalNotice(data);
      parseReloadedElements(data);
      loadChoicesJS();
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
}

function parseRubricsDisplay(data) {
  for(const rubric_display of data.rubrics_display) {
    const el = document.getElementById(rubric_display.path);

    if(rubric_display.displayed == false) {
      el.classList.add('d-none');
      el.querySelectorAll('.asterisque').forEach(element => element.textContent = '');
      el.querySelectorAll('input, select, textarea').forEach(element => element.setAttribute('disabled', 'true'));
    } else {
      el.classList.remove('d-none');
      if(rubric_display.mandatory == true) {
        el.querySelectorAll('.asterisque').forEach(element => element.textContent = ' *');
      } else {
        el.querySelectorAll('.asterisque').forEach(element => element.textContent = '');
      }
      el.querySelectorAll('input, select, textarea').forEach(element => element.removeAttribute('disabled'));
    }
  }
}

function parseElementsDisplay(data) {
  for(const element_display of data.elements_display) {
    if(element_display.display === 0) {
      document.getElementById(element_display.path).classList.add('d-none');
    } else {
      document.getElementById(element_display.path).classList.remove('d-none');
    }
  }
}

function parseMultiplesDisplay(data) {
  for(const multiple_display of data.multiples_display) {
    if(multiple_display.display === 0) {
      document.getElementById(multiple_display.path).classList.add('d-none');
    } else {
      document.getElementById(multiple_display.path).innerHTML = multiple_display.html;
    }
  }
}

function rebuildSidebar(data) {
  if(data.sidebar) {
    document.getElementById('sidebar').innerHTML = data.sidebar.html;
    document.getElementById('next_form_step').value = data.sidebar.next_form_step;
  }
}

function rebuildLegalNotice(data) {
  if(data.legal_notice) {
    replaceElement(document.getElementById('legal-notice-validation-block'), data.legal_notice.validation_block);
    replaceElement(document.getElementById('legal-notice-content-buttons'), data.legal_notice.content_buttons);
  }
}

function parseReloadedElements(data) {
  let reload = false;
  for(const element of data.reloaded_elements) {
    // console.log(element);
    document.getElementById(element.path).innerHTML = element.html;
    if(element.path.includes('DF.79') || element.path.includes('DF.80') || element.path.includes('DF.81')) {
      reload = true;
    }
  }
  if(reload == true) {
    reloadChoicesJS();
  }
}
