import { Controller } from "@hotwired/stimulus"

const axios = require('axios');
const notie = require('notie');
import tippy from 'tippy.js';
import { manageOkResponse } from '../../javascripts/custom_events';

// The element should have following attributes:
// data-controller="html--search-field" -> To define the controller, must be on the element
// data-action="input->html--search-field#searchQuery" -> The event to trigger the search

let timeout;

export default class extends Controller {
  searchQuery() {
    const config = {
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    let value = this.element.value;

    clearTimeout(timeout);

    timeout = setTimeout(function () {
      let params = new URLSearchParams(location.search);
      params.delete('page');
      params.delete('query');

      params.append('query', value);

      let newQuery = `${location.href.split('?')[0]}?${params.toString()}`;
      history.pushState(null, '', newQuery);

      // Remove query string from url to be sure we don't keep page params
      axios.get(newQuery, config)
        .then(function (response) {
          // handle success
          manageOkResponse(response.data);
          // Reload tooltips
          tippy('.tippy-tooltip', { allowHTML: true, placement: 'auto' });
        })
        .catch(function (error) {
          notie.alert({ text: error.response.data, type: '3', position: 'bottom', time: 6 });
        })

      // updateOnFormFieldChange(event.target, event.target.value);
      // console.log(event.target.value);
      // Remove query string from url to be sure we don't keep page params
      // window.location = `${location.href.split('?')[0]}?query=${event.target.value}`
    }, 400);
  }
}