import { Controller } from "@hotwired/stimulus"

// The element should have following attributes:
// data-controller="customers--procuration" -> To define the controller, must be outside the 2 links
// data-customers--procuration-target="download" -> The link aection to download
// data-customers--procuration-target="upload" -> The part section to upload
// data-action="click->customers--procuration#switchLinks" -> The event to trigger the switch of links
// data-action="click->customers--procuration#backLinks" -> The event to trigger the switch of links

export default class extends Controller {
  static targets = [ "download", "upload", "back" ]

  switchLinks() {
    this.downloadTarget.classList.add('d-none');
    this.uploadTarget.classList.remove('d-none');
  }

  backLinks() {
    this.downloadTarget.classList.remove('d-none');
    this.uploadTarget.classList.add('d-none');
  }
}