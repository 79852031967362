import { Controller } from "@hotwired/stimulus"

const axios = require('axios');
const notie = require('notie');

// The element should have following attributes:
// data-controller="customers--siren-field" -> To define the controller, must be on the element
// data-action="input->customers--siren-field#searchSiren" -> The event to trigger the search

export default class extends Controller {
  // This permits to load the siren search on page load if the siren field is filled
  connect() {
    if(this.element.value.length == 9) {
      this.searchSiren();
    }
  }

  searchSiren() {
    let siren = this.element.value.replace(/\s/g, '');

    if(siren.length === 9) {
      axios.get(`/search/siren_for_customer_creation/${siren}.json`)
        .then(function (response) {
          // handle success
          const data = response.data;
          document.getElementById('customer_name').value = data['entite']['denomination'];
          document.getElementById('customer_address').value = data['entite']['adresse']['adresse_complete'];
          document.getElementById('customer_address_complement').value = data['entite']['adresse']['complement_adresse'];
          document.getElementById('customer_zip_code').value = data['entite']['adresse']['code_postal'];
          if(document.getElementById('customer_insee_code')) document.getElementById('customer_insee_code').value = data['entite']['adresse']['code_commune'];
          document.getElementById('customer_city').value = data['entite']['adresse']['libelle_commune'];
          if(document.getElementById('customer_vat')) document.getElementById('customer_vat').value = data['entite']['num_tva'];
          // if(data['entite']['adresse']['libelle_pays']) document.getElementById('customer_country').value = data['entite']['adresse']['libelle_pays'];
        })
        .catch(function (error) {
          notie.alert({ text: error.response.data, type: '3', position: 'bottom', time: 6 });
        })
    }
  }
}