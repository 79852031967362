import { Controller } from "@hotwired/stimulus"

// The element should have following attributes:
// data-controller="html--toggle-element"  -> To define the controller, must be on the element
// data-hide-text="Cacher les détails" -> The text displayed when the element is in state 'show'
// data-show-text="Afficher les détails" -> The text displayed when the element is in state 'hidden'
// data-action="click->html--toggle-element#toggle"  -> The event to trigger the toggle
// data-state="hidden"  -> The starting state, hidden or show

export default class extends Controller {
  connect() {
    this.updateText();
  }

  toggle(event) {
    event.preventDefault();
    this.doToggle();
  }

  doToggle() {
    if(this.element.getAttribute('data-state') == 'hidden') {
      this.element.setAttribute('data-state', 'show');
      document.getElementById(this.element.getAttribute('data-target')).classList.remove('d-none');
    } else {
      this.element.setAttribute('data-state', 'hidden');
      document.getElementById(this.element.getAttribute('data-target')).classList.add('d-none');
    }
    this.updateText();
  }

  updateText() {
    if(this.element.getAttribute('data-state') == 'hidden') {
      this.element.innerText = this.element.getAttribute('data-show-text');
    } else {
      this.element.innerText = this.element.getAttribute('data-hide-text');
    }
  }
}