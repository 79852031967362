import { on } from '../javascripts/utils';

// Calculations for RBE
const operations = [
  { 'DFRBEDF8': ['DFRBEDF9', 'DFRBEDF10'] },
  { 'DFRBEDF12': ['DFRBEDF13', 'DFRBEDF14'] },
  { 'DFRBEDF3': ['DFRBEDF6', 'DFRBEDF7', 'DFRBEDF8', 'DFRBEDF12'] },
  { 'DFRBEDF22': ['DFRBEDF23', 'DFRBEDF24', 'DFRBEDF25'] },
  { 'DFRBEDF26': ['DFRBEDF27', 'DFRBEDF28', 'DFRBEDF29'] },
  { 'DFRBEDF16': ['DFRBEDF19', 'DFRBEDF20', 'DFRBEDF21', 'DFRBEDF22', 'DFRBEDF26'] },
  { 'DFRBEDF39': ['DFRBEDF40', 'DFRBEDF41'] },
  { 'DFRBEDF42': ['DFRBEDF43', 'DFRBEDF44'] }
]

export function transformPercentagesOnType() {
  on('.app-body', 'keyup', 'input.percentage-field', event => {
    event.target.value = event.target.value.replace('.', ',').replace(/[^0-9|,]/g, '');
    calculatePercentageTotals(event.target);
  });
}

// Recalculate totals for readonly fields
function calculatePercentageTotals(element) {
  // Find the klass of the element
  calculatePercentageForOne(Array.from(element.classList).find(klass => klass.startsWith('DFRBEDF')));
}

function calculatePercentageForOne(klass) {
  // Find the calcul in operations constant
  const match = operations.filter(obj => Object.keys(obj).some(key => obj[key].includes(klass)))[0];
  // console.log(match);
  if(!match) return;

  const result = Object.keys(match)[0];
  const values = Object.values(match)[0];

  let total = 0.0;

  values.forEach(value => {
    // Transform comma to point for calculations
    let subTotal = parseFloat(document.getElementsByClassName(value)[0].value.replace(',', '.'));
    if(!subTotal) return;

    total += subTotal;
  });
  // If total == 0 then remove value completely, otherwise form validation fails
  if(total === 0) {
    document.getElementsByClassName(result)[0].value = null;
  } else {
    // Put back value in field with comma
    document.getElementsByClassName(result)[0].value = total.toFixed(2).toString().replace('.', ',');
  }

  // Try to update another(s) inputs if the result is found in other calculations
  calculatePercentageForOne(result);
}
