import { Controller } from "@hotwired/stimulus"

const axios = require('axios');
import { manageOkResponse } from '../../javascripts/custom_events';
import { confirmOK } from '../../javascripts/utils';

// The element should have following attributes:
// data-controller="html--json-link"  -> To define the controller, must be on the element
// data-http-method -> Http method to use
// data-action="click->html--json-link#doRequest"  -> The event to trigger the request
// If confirmation wanted
// data-ask-confirm="Text to confirm"

export default class extends Controller {
  doRequest(event) {
    event.preventDefault();

    if(!this.element.getAttribute('data-ask-confirm') || confirmOK(this.element.getAttribute('data-ask-confirm'))) {
      axios({
        url: this.element.href,
        method: this.element.getAttribute('data-http-method'),
        headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content } })
      .then(function (response) {
        // console.log(response.data);
        manageOkResponse(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    }
  }
}