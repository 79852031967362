import { Controller } from "@hotwired/stimulus"

// The element should have following attributes:
// data-controller="signatures--manual" -> To define the controller, must be outside the 2 links
// data-signatures--manual-target="download" -> The link action to download
// data-action="click->signatures--manual#showUpload" -> The event to trigger the display of upload part

export default class extends Controller {
  static targets = [ "download", "upload" ]

  showUpload() {
    // this.downloadTarget.classList.add('d-none');
    this.uploadTarget.classList.remove('d-none');
  }
}