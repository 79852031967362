// Contains custom JS events
import $ from 'jquery';
import { manageFormErrors } from '../javascripts/forms';
import { goToPage, replacePage, on, replaceElement, loadChoicesJS, choicesList, fillForeignLegalForm, showLoader } from '../javascripts/utils';
import { needRecording, recordAction, updateFormRubric } from '../javascripts/forms';
import { transformPercentagesOnType } from '../javascripts/rbe';
const notie = require('notie');
const axios = require('axios');
import List from 'list.js';
import tippy from 'tippy.js';
import { loadInpiEvents, loadPreviewInpiEvents, loadAutomaticSignatureEvents } from './inpi';
// import coreui from '@coreui/coreui';
// import Choices from 'choices.js';

// import tooltip from 'bootstrap';

// Events to be loaded at oage load
window.addEventListener("load", () => {
  // workaround for Firefox
  // ?. is the new safe operator
  document.querySelector('input[autofocus="autofocus"]')?.focus();

  // $('[data-toggle="tooltip"]').tooltip({ html: true, placement: 'auto' });
  tippy('.tippy-tooltip', { allowHTML: true, placement: 'auto' });

  const page = document.body.getAttribute('data-page');

  if(page == 'form_contents/form' || page == 'pro_qualifications/edit' || page == 'managers/edit' ||
     page == 'effective_beneficiaries/edit' || page == 'previous_owners/edit' ||
     page == 'establishment_managers/edit' || page == 'establishments/edit' ||
     page == 'closed_establishments/edit' || page == 'rcs_managers/edit' ||
     page == 'fusion_companies/edit' || page == 'activities/edit') InitFormContentFormEvents();

  if(page == 'form_content_steps/search_siren') {
  // Focus the parent of the element because the element is dynamically replaced
  // $(".app-body").on('keyup', '.main', function() {
  //   console.log($(this));
  // });
    on('.app-body', 'keyup', '#base_company_infos_adresse_code_postal', event => {
      searchByZipCodeBaseCompanyInfos(event.target);
    });
    let sirenField = document.getElementById('siren');
    if(sirenField.getAttribute('data-siren')) {
      fillSiren(sirenField.getAttribute('data-siren'), sirenField);
    }
  }

  on('.app-body', 'click', '.clickable-tr td:not([class="form-content-note-container"])', event => {
    goToPage(event.target.parentElement.getAttribute('data-url'));
  });

  // $(".clickable-tr td:not(.td-actions)").on("click", function() {
  //   goToPage($(this).parent().attr('data-url'));
  // });

  if(document.body.getAttribute('data-page') == 'form_contents/form' &&
     document.body.contains(document.getElementById('legal-notice-form'))) ListenChangeEvents();

  // Form events
  loadRemoteElements();
  loadAttachmentsEvents();
  loadFormButtonEvents();
  // loadRemoteForms();
  // loadRemoteSelects();
  loadCreationTypeEvents();
  loadSirenFieldForCreation();
  loadListTables();
  loadChoicesJS();
  transformSirenOnType();
  transformNamesOnType();
  autoCapitalizeOnType();
  loadNewspaperEvents(page);
  loadLegalNoticePresetsEvent(page);
  loadTextAreaEvents();
  loadModalEvents();
  loadNoteEvents();
  // loadActivityEvents();
  // loadDelayedImages();
  loadPasswordFieldsEvents();
  loadInpiEvents();
  if(document.body.getAttribute('data-page') == 'form_contents/preview_inpi') loadPreviewInpiEvents();
  if(document.body.getAttribute('data-page') == 'form_contents/signatures/automatic') loadAutomaticSignatureEvents();
  loadQueryStringEvents();
});

function loadQueryStringEvents() {
  const urlParams = new URLSearchParams(window.location.search);
  if(urlParams.get('after_load') == 'check_data') {
    document.getElementById('check-data-button').click();
  }
}

function ListenChangeEvents() {
  let timeout;

  on('.app-body', 'keyup', 'input.form-control', event => {
    if(event.target.type === 'date') return;

    clearTimeout(timeout);
    hideLegalNoticeOrderButtons();

    timeout = setTimeout(function () {
      updateOnFormFieldChange(event.target, event.target.value);
    }, 500);
  });

  // Changing the value of a date filed by clicking on the calendar
  on('.app-body', 'change', "input[type='date']", event => {
    clearTimeout(timeout);
    hideLegalNoticeOrderButtons();

    timeout = setTimeout(function () {
      updateOnFormFieldChange(event.target, event.target.value);
    }, 500);
  });
}

function hideLegalNoticeOrderButtons() {
  document.getElementById('legal-notice-order-buttons')?.classList?.add('d-none');
  document.getElementById('legal-notice-validating-input')?.classList?.remove('d-none');
}

function InitFormContentFormEvents() {
  on('.app-body', 'change', ".form-select", event => {
    const element = event.target;

    // Remove error message if selecting a value
    if(element.value) {
      element.classList.remove('is-invalid');
    }

    if(!element.classList.contains('no-update')) {
      updateOnFormFieldChange(element, element.value);
    }

    if(needRecording() === true) {
      recordAction('select', returnPathFromName(element.name) || element.name, element.options[element.selectedIndex].innerHTML);
    }
  });

  on('.app-body', 'change', ".form-checkbox", event => {
    let newValue = '';
    const element = event.target;

    if(element.checked) {
      newValue = element.value;
    } else {
      // Get previous hidden field value if unchecked
      newValue = element.previousElementSibling.value;
    }
    updateOnFormFieldChange(element, newValue);

    if(needRecording() === true) {
      recordAction('check', returnPathFromName(element.name), '');
    }
  });

  on('.app-body', 'change', 'input[type="radio"]', event => {
    let newValue = '';
    const element = event.target;

    newValue = element.value;
    updateOnFormFieldChange(element, newValue);

    if(needRecording() === true) {
      recordAction('choose', returnPathFromName(element.name), element.nextElementSibling.textContent);
    }
  });

  InitMultipleFieldsEvents();

  if(needRecording() === true) {
    $(".app-body").on('blur', 'input[type="text"]', function() {
      recordAction('fill', returnPathFromName($(this).attr("name")) || $(this).attr("name"), $(this).val());
    });
    $(".app-body").on('blur', 'input[type="date"]', function() {
      recordAction('fill', returnPathFromName($(this).attr("name")) || $(this).attr("name"), $(this).val());
    });
    $(".app-body").on('blur', 'textarea', function() {
      recordAction('fill', returnPathFromName($(this).attr("name")) || $(this).attr("name"), $(this).val());
    });

    $(".app-body").on('click', 'input[type="submit"]', function(event) {
      // Check if the event is a human event or not
      // If original event is not defined then it's triggered programmatically
      // otherwise it's human
      if ( event.originalEvent !== undefined ) {
        recordAction('click', 'submit', $(this).val());
      }
    });
    $(".app-body").on('click', 'a.btn-primary', function() {
      recordAction('click', 'a', $(this).text());
    });

    $(".app-body").on('click', 'a.list-link', function() {
      recordAction('click', 'a', $(this).text(), this.id);
    });
  }

  // Country selects
  on('.app-body', 'change', '.country-select', event => {
    replaceCityField(event.target);
  });
  // Search by siren
  on('.app-body', 'keyup', '.search-siren-field', event => {
    searchBySiren(event.target);
  });
  // Search by zip code
  on('.app-body', 'keyup', '.search-zip-code-field', event => {
    searchByZipCode(event.target);
  });
  // Select legal form in select
  on('.app-body', 'change', '.legal-form-select', event => {
    fillForeignLegalForm(event.target);
  });
  on('.app-body', 'click', '.change-step', event => {
    event.preventDefault();
    const el = event.target;

    if(needRecording() === true) {
      let text = el.querySelector('.nav-item') ? el.querySelector('.nav-item').querySelector('.nav-link').textContent : el.textContent;
      recordAction('click', 'step', text.replace(/\n/g, '').trim());
    }

    if(document.body.contains(document.getElementById("next_form_step"))) document.getElementById('next_form_step').value = el.getAttribute('data-step');
    document.getElementById('continue_submit').click();
  });
  // Fill permanent representative infos
  on('.app-body', 'change', '.perm-rep-status-select', event => {
    axios.get(document.getElementById('fill_rep_url').value,
    { params: {
        rubric_value: event.target.value,
        path: returnPathFromName(event.target.getAttribute("name"))
      }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  });

  transformPercentagesOnType();
}

function InitMultipleFieldsEvents() {
  var timeouts = {};

  var elements = $('.form-multiple').find('.form-control');

  elements.each(function() { timeouts[this.id] = null; });

  elements.on( "keyup", function( event ) {
    var el = $(this);
    clearTimeout(timeouts[this.id]);

    timeouts[this.id] = setTimeout(function () {
      var name = returnPathFromName(el.attr("name"));

      updateFormRubric(el.val(), name, 'simple');
    }, 300);
  });
}

function updateOnFormFieldChange(element, newValue) {
  var name = returnPathFromName(element.name);

  updateFormRubric(newValue, name, 'complete');
}

// Return only the path from a name like rubric_names[ICM/M01/M01.1]
function returnPathFromName(name) {
  return name.substring(
    name.lastIndexOf("[") + 1,
    name.lastIndexOf("]")
  );
}

// Save legal notice text and return to form_content or go to choose newspaper page
function saveLegalNoticeAndAction(element) {
  document.getElementById('form_after_action').value = element.getAttribute('data-action');
  document.getElementById('legal_notice_submit').click();

  return false;
}

function searchBySiren(element) {
  var siren = element.value.replace(/\s/g, '');
  // console.log(siren);
  if(siren.length != 9 || siren === element.getAttribute('data-last-value')) {
    return false;
  } else {
    element.setAttribute('data-last-value', siren);
    // $.ajax({
    //   url: '/search/siren/' + element.attr('data-form-content-id') + '/' + siren,
    //   method: 'GET',
    //   data: { path: returnPathFromName(element.attr("name")) }
    // });
    axios.get('/search/siren/' + element.getAttribute('data-form-content-id') + '/' + siren + '.json',
    { params: {
        path: returnPathFromName(element.getAttribute("name"))
      }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }
}

function searchByZipCodeBaseCompanyInfos(element) {
  var zipCode = element.value.replace(/\s/g, '');

  if(zipCode.length != 5 || zipCode === element.getAttribute('data-last-value')) {
    return false;
  } else {
    element.setAttribute('data-last-value', zipCode);
    // $.ajax({
    //   url: '/search/zip_code/' + element.attr('data-form-content-id') + '/' + zipCode,
    //   method: 'GET',
    //   data: { path: returnPathFromName(element.attr("name")) }
    // });
    axios.get('/search/by_zip_code_base_company_infos.json',
    { params: {
        zip_code: zipCode
      }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }
}

function searchByZipCode(element) {
  var zipCode = element.value.replace(/\s/g, '');

  if(zipCode.length != 5 || zipCode === element.getAttribute('data-last-value')) {
    return false;
  } else {
    element.setAttribute('data-last-value', zipCode);
    // $.ajax({
    //   url: '/search/zip_code/' + element.attr('data-form-content-id') + '/' + zipCode,
    //   method: 'GET',
    //   data: { path: returnPathFromName(element.attr("name")) }
    // });
    axios.get('/search/zip_code/' + element.getAttribute('data-form-content-id') + '/' + zipCode + '.json',
    { params: {
        path: returnPathFromName(element.getAttribute("name"))
      }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }
}

function replaceCityField(element) {
  axios.get('/forms/replace_city_field/' + element.getAttribute('data-form-content-id') + '.json',
    { params: {
      path: returnPathFromName(element.getAttribute("name")),
      country: element.value }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
}

function loadPasswordFieldsEvents() {
  // Password fields
  on('.app', 'click', '.password-field-icon', event => {
    const input = document.getElementById(event.target.getAttribute('data-toggle'));
    input.setAttribute('type', input.getAttribute('type') == 'password' ? 'text' : 'password');

    const child = event.target.firstChild;
    if(child.classList.contains('fa-eye-slash')) {
      child.classList.remove('fa-eye-slash');
      child.classList.add('fa-eye');
    } else {
      child.classList.add('fa-eye-slash');
      child.classList.remove('fa-eye');
    }

    input.focus();
  });
}

// function loadActivityEvents() {
//   on('.app-body', 'change', ".form-select", event => {
//     console.log(event.target.value.split('/'))
//     const activities = event.target.value.split('/');
//     document.getElementById('DF78').value = activities[0];
//     document.getElementById('DF79').value = activities[1];
//     document.getElementById('DF80').value = activities[2];
//     document.getElementById('DF81').value = activities[3];
//   });
// }

function loadNoteEvents() {
  on('.app-body', 'click', '.form-content-note', event => {
    axios.get(`/form_contents/${event.target.getAttribute('data-form-content-id')}/comments/new.json`,
    { params: {
      from: event.target.getAttribute("data-from")
    }
    })
    .then(function (response) {
      manageOkResponse(response.data);
      setTimeout(function () {
        document.getElementById('form_content_comment_field').focus();
      }, 300);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  });
}

function loadModalEvents() {
  on('.app-body', 'click', '.modal-pdf-src', event => {
    document.getElementById('pdf-modal-embed').src = event.target.getAttribute('data-src');
  });
}

// function loadDelayedImages() {
//   document.querySelectorAll(".delayed-image").forEach(image => {
//     let downloadingImage = new Image();
//     downloadingImage.onload = function(){
//       image.src = this.src;
//     };

//     downloadingImage.src = image.getAttribute('data-src');
//   });
// }

function loadLegalNoticePresetsEvent(page) {
  if(page != 'legal_notice_steps/set_text') return;

  // Update the value for parution date when changing the select
  on('.app-body', 'change', 'select#legal_notice_preset_id', event => {
    // console.log(event.target.value);
    // console.log(document.getElementById('lnp-' + event.target.value))

    if(event.target.value) {
      const section = document.getElementById('lnp-' + event.target.value);
      document.getElementById('ntype').value = section.getAttribute('data-ntype');
      document.getElementById('nstype').value = section.getAttribute('data-nstype');
      document.getElementById('text').value = section.innerText;
      document.getElementById('text').removeAttribute('disabled');
    } else {
      document.getElementById('text').setAttribute('disabled', 'true');
    }
  });
}

function loadTextAreaEvents() {
  on('.app-body', 'keyup', 'textarea.textarea-limit', event => {
    const el = event.target;
    let limit = el.previousElementSibling;

    limit.innerHTML = `${el.value.length}/${el.getAttribute('data-limit')}`;
    if(el.value.length > el.getAttribute('data-limit')) {
      limit.classList.add('textarea-limit-section-error');
    } else {
      limit.classList.remove('textarea-limit-section-error');
    }
  });
}

function loadNewspaperEvents(page) {
  if(page != 'form_contents/legal_notices/choose_newspaper' &&
     page != 'legal_notice_steps/choose_newspaper') return;

  // Update the value for parution date when changing the select
  on('#newspapers-table', 'change', 'select.sd-parution', event => {
    // select the column just after this one
    const td = event.target.parentElement.nextElementSibling;
    // Update the hidden field there with the select value
    td.querySelector('[id=sd_parution]').value = event.target.value;
  });
}

// Capitalize last names on type, DO NOT TRIM
// See https://demat-facile.monday.com/boards/327646012/pulses/1727474717
// Capitalize first letter of first names on type
function transformNamesOnType() {
  on('.app-body', 'keyup', 'input.first-name-field', event => {
    event.target.value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
  });
}

// Auto capitalize content
function autoCapitalizeOnType() {
  on('.app-body', 'keyup', 'input.auto-capitalize', event => {
    event.target.value = event.target.value.toUpperCase();
  });
}

// Remove all characters except numbers for SIRENs fields
function transformSirenOnType() {
  on('.app-body', 'keyup', 'input.siren-field', event => {
    // element.val(element.val().replace(/[^0-9]/g, ''));
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  });
}

function loadCreationTypeEvents() {
  if(document.body.getAttribute('data-page') != 'form_content_steps/creation_types') return;

  on('.app-body', 'click', 'a.creation-type', event => {
    event.preventDefault();

    document.getElementById('legal_form').value = event.target.getAttribute('data-value');
    document.getElementById('legal_form_submit').click();
  });
}

function loadSirenFieldForCreation() {
  if(document.body.getAttribute('data-page') != 'form_content_steps/search_siren') return;

  on('.app-body', 'keyup', 'input.siren-for-creation', event => {
    // event.preventDefault();

    // document.getElementById('legal_form').value = event.target.getAttribute('data-value');
    // document.getElementById('legal_form_submit').click();
    const siren = event.target.value.replace(/[^0-9]/g, '');
    fillSiren(siren, event.target);
  });
}

function fillSiren(siren, element) {
  if(siren.length === 9) {
    document.getElementById('company-infos').textContent = "Veuillez patienter pendant la récupération des informations du RNE...";

    axios.get(`/search/siren_for_creation/${element.getAttribute('data-form-content-id')}/${siren}.json`,
    { params: {
        from: element.getAttribute('data-from')
      }
    })
    .then(function (response) {
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }
}

function loadFormButtonEvents() {
  // Vérifier les données
  on('.app-body', 'click', '.check-data', event => {
    document.getElementById('form_action').value = 'validate';
  });
  // Enregistrer et continuer
  on('.app-body', 'click', '.save-and-continue', event => {
    document.getElementById('form_action').value = 'continue';
  });
  // Affiche le loader d'attente pour les annonces légales
  on('.app-body', 'click', '.show-jal-loader', event => {
    document.getElementById('hover-body').classList.remove('d-none');
    document.getElementById('hover-body-jal').classList.remove('d-none');
  });
  // Affiche le loader d'attente général
  on('.app-body', 'click', '.show-loader', event => {
    showLoader(event.target.getAttribute('data-text'));
  });
  // Save legal notice action
  on('.app-body', 'click', '.save-legal-notice', event => {
    event.preventDefault();

    // console.log('test')
    saveLegalNoticeAndAction(event.target);
  });
  // Save form by clicking to the according submit button
  // on('.app-body', 'click', '.save-form', event => {
  //   event.preventDefault();

  //   document.getElementById(event.target.getAttribute('data-submit')).click();
  // });
}

function loadAttachmentsEvents() {
  // attachments/index est pour les reprises de formalité rejetées
  if (document.body.getAttribute('data-page') != 'form_contents/form' &&
      document.body.getAttribute('data-page') != 'attachments/index') return;

  // When adding a file to upload
  // Submit the form
  on('#attachments-list', 'change', '.attachment-file', event => {
    event.target.nextElementSibling.click();
  });
  // Changing other attachment type select configures the add link
  on('#attachments-list', 'change', '.attachment-type', event => {
    const element = event.target;
    document.getElementById(element.getAttribute('data-target'))
            .setAttribute('data-params', `attachment_type=${element.value}`);
  });
  on('#attachments-list', 'change', '#sub_type', event => {
    // console.log(event.target.value);
    event.target.closest('tr').querySelector('#attachment_sub_type').value = event.target.value;
  });
}

function loadRemoteElements() {
  document.addEventListener("ajax:success", (event) => {
    const element = event.target;

    if(element.hasAttribute('data-action')) {
      manageDataAction(element.getAttribute('data-action'), JSON.parse(event.detail[2].response));
    } else {
      manageOkResponse(JSON.parse(event.detail[2].response));
    }
  });

  document.addEventListener("ajax:error", (event) => {
    // Do not answer to children potential events
    // if (element !== event.target) return;
    // const [_data, _status, xhr] = event.detail;
    manageFormErrors(JSON.parse(event.detail[2].response));
  });
}

function manageDataAction(action, data) {
  switch(action) {
    case 'act-create':
      manageActCreation(data);
    break;
    case 'multiple-field':
      manageMultipleField(data);
    break;
    default:
  }
}

function manageMultipleField(data) {
  // console.log('test');
  const el = document.getElementById(data.target);
  replaceElement(el, data.html);
  // Does not work right now
  // if(data.focus == true) {
  //   console.log('focus')
  //   // $($(el.find('.form-row').get(-1)).find(':input')[0]).focus();
  //   // Focus last input in the list
  //   let inputs = el.querySelectorAll('input');
  //   inputs[inputs.length-1].focus();
  // }

  InitMultipleFieldsEvents();
}

function manageActCreation(data) {
  document.getElementById('existing-acts').insertAdjacentHTML('afterbegin', data.act);
  document.getElementById('add-act').innerHTML = data.form_block;
  document.getElementById('act_act_date').focus();
  document.getElementById('no-act-sentence').classList.add('d-none');
}

export function manageOkResponse(response) {
  if (response.actions) {
    manageActionsFromArray(Array.from(response.actions));
  } else {
    manageSingleAction(response);
    manageOldNotification(response);
  }
}

export function manageActionsFromArray(actions) {
  actions.forEach(action => manageSingleAction(action));
}

function manageSingleAction(response) {
  switch(response.action) {
    case 'redirect':
      goToPage(response.url);
      break;
    case 'redirect_replace':
      replacePage(response.url);
      break;
    case 'replace_html':
      const el = returnElementByTarget(response);
      if(el) el.innerHTML = response.html;
      // triggerVueRefresh()
      loadChoicesJS();
      break;
    // replace the entire element. not only the innerHTML
    case 'replace_element':
      // console.log('replace_element');
      replaceElement(returnElementByTarget(response), response.html);
      // triggerVueRefresh()
      break;
    case 'remove_html':
      removeHtml(response);
      break;
    case 'insert_element':
      insertElement(response);
      break;
    case 'notification':
      manageNotification(response);
      break;
    case 'display_element':
      displayElement(response);
      break;
    case 'set_value':
      setValue(response);
      break;
    case 'set_value_if_empty':
      setValueIfEmpty(response);
      break;
    case 'add_class':
      addClass(response);
      break;
    case 'init_list':
      initList(document.getElementById(response.target));
      break;
    case 'hide_loader':
      document.getElementById('hover-body').classList.add('d-none');
      document.getElementById('hover-body-text').classList.add('d-none');
      break;
    case 'disable_element':
      document.getElementById(response.target).setAttribute('disabled', 'true');
      break;
    case 'enable_element':
      document.getElementById(response.target).removeAttribute('disabled');
      break;
    case 'close_modal':
      $(`#${response.target}`).modal('hide');
      // document.getElementById(response.target).modal('hide');
      break;
    case 'reload_tooltips':
      tippy('.tippy-tooltip', { allowHTML: true, placement: 'auto' });
      break;
    case 'empty_html':
      const el2 = returnElementByTarget(response);
      if(el2) el2.innerHTML = '';
      // triggerVueRefresh()
      break;
    case 'click':
      document.getElementById(response.target).click();
      break;
    default:
      // code block
  }
}

function initList(element) {
  let options = {
    page: element.getAttribute('data-list-paginate'),
    valueNames: element.getAttribute('data-list-options').split(','),
    pagination: element.hasAttribute('data-list-paginate') ? [{
      item: "<li class='page-item page'><span class='page-link page'></span></li>"
    }] : null
  };

  const list = new List(element.id, options);
  if(element.hasAttribute('data-list-sort')) {
    list.sort(element.getAttribute('data-list-sort'), { order: "asc" });
  }
}

function loadListTables() {
  Array.from(document.getElementsByClassName('list-table')).forEach(element => {
    initList(element);
  });

  // Focus on first search field found
  // ?. is the new safe operator
  document.getElementsByClassName('search')[0]?.focus();

  // Do not scroll on top when clicking on
  // on('.app-body', 'click', 'a.page', event => {
  //   event.preventDefault();
  // });
}

function addClass(response) {
  const selection = returnElementByTarget(response);

  if(Object.prototype.toString.call(selection) === '[object NodeList]') {
    const array = Array.from(selection);
    array.forEach(element => {
      element.classList.add(response.class_name);
    });
  } else {
    if(!selection) return;
    // Other cases
    selection.classList.add(response.class_name);
  }
}

function setValue(response) {
  const selection = returnElementByTarget(response);

  // querySelectorAll
  if(Object.prototype.toString.call(selection) === '[object NodeList]') {
    Array.from(selection).forEach(element => {
      setRightValue(element, response.value);
    });
  } else {
    if(!selection) return;

    setRightValue(selection, response.value);
  }
}

function setValueIfEmpty(response) {
  const selection = returnElementByTarget(response);
  if(selection.value) return;

  setValue(response);
}

function setRightValue(element, value) {
  if(element.classList.contains("choices-js")) {
    choicesList[element.name].setChoiceByValue(value);
  } else {
    element.value = value;
  }
  updateFormForLegalNotice(element);
}

// Regen legal notice form text
function updateFormForLegalNotice(element) {
  if(document.body.getAttribute('data-page') === 'form_contents/form' && document.body.contains(document.getElementById("legal-notice-form"))) {
    updateOnFormFieldChange(element, element.value);
  }
}

function displayElement(response) {
  const selection = returnElementByTarget(response);

  // querySelectorAll
  if(Object.prototype.toString.call(selection) === '[object NodeList]') {
    const array = Array.from(selection);
    array.forEach(element => element.style.display = 'block');
  } else {
    if(!selection) return;
    // Other cases
    selection.style.display = 'block';
  }
}

function removeHtml(response) {
  const selection = returnElementByTarget(response);
  // querySelectorAll
  if(Object.prototype.toString.call(selection) === '[object NodeList]') {
    const array = Array.from(selection);
    array.forEach(element => element.remove());
  } else {
    if(!selection) return;
    // Other cases
    selection.remove();
  }
}

function returnElementByTarget(response) {
  switch(response.target_type) {
    case 'id':
      return document.getElementById(response.target);
      break;
    case 'query_selector':
      return document.querySelector(response.target);
      break;
    case 'query_selector_all':
      return document.querySelectorAll(response.target);
      break;
    default:
      return document.getElementById(response.target);
  }
}

// function triggerVueRefresh() {
//   const event = new Event('VueRefresh');
//   document.dispatchEvent(event);
// }

function manageNotification(response) {
  const types = new Map([['success', 1], ['error', 3], ['info', 4]]);
  notie.alert({ text: response.message, type: types.get(response.type), position: 'bottom', time: 10 });
}

function manageOldNotification(data) {
  if(!data.message) return;

  const types = new Map([['success', 1], ['error', 3], ['info', 4]]);
  notie.alert({ text: data.message.text, type: types.get(data.message.type), position: 'bottom', time: 10 });
}

// User position of method insertAdjacentHTML
// Possible positions: beforebegin, afterbegin, beforeend, afterend
// https://developer.mozilla.org/fr/docs/Web/API/Element/insertAdjacentHTML
function insertElement(response) {
  returnElementByTarget(response).insertAdjacentHTML(response.position, response.html);
}
