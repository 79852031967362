import { Controller } from "@hotwired/stimulus"

// const axios = require('axios');
// const notie = require('notie');

// The element should have following attributes:
// data-controller="users--password-format" -> To define the controller, must be outside of the elements
// data-users--password-format-target="password" -> The field for the first password
// data-users--password-format-target="passwordConfirmation" -> The field for the second password
// data-action="input->users--password-format#comparePasswords" -> The event to trigger the comparison

export default class extends Controller {
  static targets = [ "password", "passwordConfirmation", "lengthTest", "passwordsEqual" ];

  connect() {
    this.comparePasswords();
  }

  comparePasswords() {
    // console.log(this.passwordTarget.value);
    // console.log(this.passwordConfirmationTarget.value);

    if(this.passwordTarget.value.length >= 8) {
      this.lengthTestTarget.innerHTML = "<i class='fas fa-check text-success'></i> Le mot de passe fait plus de 8 caractères !";
    } else {
      this.lengthTestTarget.innerHTML = '<i class="fas fa-times text-danger"></i> Le mot de passe doit faire plus de 8 caractères.';
    }

    if(this.passwordTarget.value.length > 0 && this.passwordTarget.value == this.passwordConfirmationTarget.value) {
      this.passwordsEqualTarget.innerHTML = "<i class='fas fa-check text-success'></i> Les mots de passe correspondent !";
    } else {
      this.passwordsEqualTarget.innerHTML = '<i class="fas fa-times text-danger"></i> Les mots de passe ne correspondent pas.';
    }
  }
}