import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "username", "password", "button" ]

  connect() {
    this.enableButton();
  }

  enableButton() {
    if(this.usernameTarget.value && this.passwordTarget.value) {
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', 'disabled');
    }
  }
}