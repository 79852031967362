import { Controller } from "@hotwired/stimulus"

// const axios = require('axios');
// import { manageOkResponse } from '../../javascripts/custom_events';
// import { confirmOK } from '../javascripts/utils';

// The element should have following attributes:
// data-controller="html--transform-on-type" -> To define the controller, must be on the element
// data-action="input->html--transform-on-type#transform" -> The event to trigger the request
// data-remove-whitespaces="true" -> Remove spaces in string
// data-strip="true" -> Remove beginning and trailing spaces in string
// data-only-numbers="true" -> Allow only numbers in string
// data-auto-capitalize="true" -> Capitalize string
// data-capitalize-first="true" -> Capitalize first letter in string

export default class extends Controller {
  transform() {
    if(this.element.getAttribute('data-remove-whitespaces') == 'true') this.removeSpaces();
    if(this.element.getAttribute('data-strip') == 'true') this.strip();
    if(this.element.getAttribute('data-only-numbers') == 'true') this.onlyNumbers();
    if(this.element.getAttribute('data-auto-capitalize') == 'true') this.autoCapitalize();
    if(this.element.getAttribute('data-capitalize-first') == 'true') this.capitalizeFirst();
  }

  removeSpaces() {
    this.element.value = this.element.value.replace(/\s/g,'');
  }

  strip() {
    this.element.value = this.element.value.trim();
  }

  onlyNumbers() {
    this.element.value = this.element.value.replace(/[^0-9]/g, '');
  }

  autoCapitalize() {
    this.element.value = this.element.value.toUpperCase();
  }

  capitalizeFirst() {
    this.element.value = this.element.value.charAt(0).toUpperCase() + this.element.value.slice(1);
  }
}