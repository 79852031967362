const axios = require('axios');
import { hideLoader, on, showLoader } from '../javascripts/utils';
import { manageOkResponse } from '../javascripts/custom_events';

export function loadPreviewInpiEvents() {
  showLoader("L'Inpi génère la synthèse de votre formalité. Merci de patienter...");

  checkSynthesisPresence();
}

export function loadAutomaticSignatureEvents() {
  showLoader("Notre système va signer votre synthèse. Merci de patienter...");

  checkAutomaticSignaturePresence();
}

function checkSynthesisPresence() {
  axios({
    url: document.getElementById('preview_inpi_content').getAttribute('data-url'),
    method: 'GET',
    headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content }
  })
  .then(function (response) {
    // clearInterval(interval);
    // console.log(response.data);
    manageOkResponse(response.data);
    hideLoader();
  })
  .catch(function (error) {
    // handle error
    // console.log(error);
    setTimeout(function () {
      checkSynthesisPresence()
    }, 10000);
  })
}

function checkAutomaticSignaturePresence() {
  axios({
    url: document.getElementById('automatic_signature_content').getAttribute('data-url'),
    method: 'GET',
    headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content }
  })
  .then(function (response) {
    // clearInterval(interval);
    // console.log(response.data);
    manageOkResponse(response.data);
    // hideLoader();
  })
  .catch(function (error) {
    // handle error
    // console.log(error);
    setTimeout(function () {
      checkAutomaticSignaturePresence()
    }, 10000);
  })
}

export function loadInpiEvents() {
  on('.app-body', 'click', '#check-credentials-link', event => {
    event.preventDefault();

    axios({
      url: event.target.href,
      method: 'POST',
      headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content },
      data: {
        username: document.getElementById('user_inpi_username').value,
        password: document.getElementById('user_inpi_password').value
      } })
    .then(function (response) {
      // console.log(response.data);
      manageOkResponse(response.data);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  });
}